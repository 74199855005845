* {
  margin: 0;
  padding: 0;
}
.container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  background: url("/src/assets/images/asteroids/bg5.jpg") no-repeat;
  background-size: cover;
}
body {
  width: 100%;
}
.info {
  font-family: 'SpaceAge';
  font-size: 32px;
  color: white;
  text-align: center;
}
.info {
  float:left;
}

.score > span {
  font-family: 'SpaceAge';
  font-size: 32px;
  color: white;
  text-align: center;
}

.orbit {
  margin-left: -75px;
  margin-top: -75px;
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: 10;
  border-radius: 50%;
  border: 2px dotted #ccc;
  padding: 10px;
  box-sizing: border-box;
  width: 150px;
  height: 150px;
}
.def {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 50%;
  top: calc(50% - 100px);
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: 100;
}
.def img, .orbit img {
  width: 100%;
  height: 100%;   
}
.asteroid {
  position: absolute;
  width: 30px;
  height: 30px;
  background: url("/src/assets/images/asteroids/def.jpg") no-repeat;
  background-size: cover;
  animation-name: spin;
  animation-duration: 1200ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: 100;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
.boom {
  width: 45px;
  height: 45px;
  background: url("/src/assets/images/asteroids/boom.png");
  position: absolute;
  background-position: -25px -10px;
  animation: boom 0.4s steps(2);
}
@keyframes boom { 
  0% { background-position: -25px -10px }
  33% {background-position: -155px -10px}
  66% { background-position: -325px -10px; }
  100% { background-position: -460px -10px; }
}
.lifes {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-top: 7px;
  margin-right: 5px;
  background: url("/src/assets/images/asteroids/lifes.png") no-repeat;
  float: right;
}
.new_game {
  background: rgba(0,0,0,0.65);
  width: 100%;
  height: 100%;
  background-size: cover;
  position: fixed;
  overflow: hidden;
  z-index: 2;
}
.play_again_button, .game {
  margin: 0 auto;
  width: 450px;
  border-radius: 5px;
  border: 3px dotted #ccc;
  margin-top: 100px;
  font-family: 'SpaceAge';
  font-size: 32px;
  color: white;
  text-align: center;
}
.play_again_button:hover, .game:hover {
  cursor: pointer;
}
h3 {
  margin: 0 auto;
  margin-top: 60px;
  font-family: 'SpaceAge';
  font-size: 20px;
  color: white;
  text-align: center;
}
h3 > span {
  margin: 0 auto;
  margin-top: 60px;
  font-family: 'SpaceAge';
  font-size: 20px;
  color: white;
  text-align: center;
}
.play_again {
  background: rgba(0,0,0,0.65);
  width: 100%;
  height: 100%;
  background-size: cover;
  position: fixed;
  display: none;
  z-index: 2;
}
.play_again h2 {
  margin-top: 100px;
}

.wrapped_link {
  position: absolute;
  padding: 30px;
  font-family: 'SpaceAge';
  font-size: 32px;
  color: white;
  text-align: center;
  bottom: 0;
}

.wrapped_link > a {
  color: white;
  font-family: 'SpaceAge';
  text-decoration: none;
}

.thx {
  position: absolute;
  font-family: 'SpaceAge';
  font-size: 32px;
  color: transparent;
  text-align: center;
  bottom: 30px;
  right: 30px;
}