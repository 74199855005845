.article_header {
  color: var(--main-text-color);
  border: 1px solid var(--main-border-color);
  padding: 10px 0;
  text-align: center;
  font-family: 'Source Code Pro', monospace;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 20px;
}