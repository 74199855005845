.skill_list {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  color: var(--main-text-color);
}

.skill_wrapper {
  padding: 3px 5px;
  margin-right: 3px;
  margin-bottom: 3px;
  border: 1px solid var(--main-text-color);
  border-radius: 5px;
}

.main_skill {
  background-color: var(--main-text-color);
  border: 1px solid var(--main-text-color);
  color: var(--reverse-text-color);
}