:root {
  --main-text-color: #000;
  --secondary-text-color: #686d71;
  --reverse-text-color: #fff;
  --link-color: #6eb2fc;

  --navbar-background-color: #fff;
  --main-background-color: #fff;

  --main-theme-color: #6667aa;
  --main-theme-box-shadow: rgba(102, 103, 170, 0.4);

  --main-border-color: #000;

  --base-font-size-desktop: 17px;
  --base-font-size-mobile: 15px;

  --navbar-padding-vertical: 0.5rem;
  --navbar-padding-horizontal: 1rem;
  --navbar-z-index: 4;
  --navbar-height: 3.75rem;
  --navbar-box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);

  --footer-box-shadow: 0 0 2px 1px rgba(0,0,0,.1);
}

html[data-theme=dark] {
  --main-text-color: #fff;
  --reverse-text-color: #000;

  --navbar-background-color: #242526;
  --main-background-color: #181919;

  --main-border-color: #fff;
}

@font-face {
  font-family: 'SpaceAge';
  src: URL(./assets/fonts/SpaceAge.ttf?#iefix) format('truetype');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

*::selection {
  color: var(--reverse-text-color);
  background-color: var(--main-theme-color);
}

@media only screen and (max-width: 996px) {
  html {
    font-size: var(--base-font-size-mobile);
  }
}

@media screen and (min-width: 997px) {
  html {
    font-size: var(--base-font-size-desktop);
  }
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: var(--main-background-color);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--navbar-background-color);
}

h1, h2 {  
  color: var(--main-text-color);
}