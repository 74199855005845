.education_wrapper {
  margin: 20px 0;
  color: var(--main-text-color);
}

.title {
  font-size: 1.1em;
}

.main_info {
  margin: 5px 0;
}

.title {
  font-weight: 500;
}

.link {
  text-decoration: none;
  color: var(--link-color);
}