.project_wrapper {
  margin: 20px 0;
  color: var(--main-text-color);
  border-bottom: solid 1px;
}

.project_wrapper:last-child {
  border: none;
}

.project_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 1.4em;
  font-weight: 500;
}

.timeActivity {
  color: var(--secondary-text-color);
  font-style: italic;
}

.position {
  color: var(--secondary-text-color);
  font-size: 1.2em;
}

.description {
  margin: 10px 0;
}

.responsibilities_list:nth-of-type(1) {
  list-style-type: '— ';
  list-style-position: inside;
  margin: 10px 0;
}

.skill_list {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  color: var(--main-text-color);
}

.technology {
  padding: 3px 5px;
  margin-right: 10px;
  margin-bottom: 5px;
  border: 1px solid var(--main-text-color);
  border-radius: 5px;
}