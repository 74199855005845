
nav {
  padding: var(--navbar-padding-vertical) var(--navbar-padding-horizontal);
  position: sticky;
  top: 0;
  height: var(--navbar-height);
  z-index: var(--navbar-z-index);
  background-color: var(--navbar-background-color);
  display: flex;
  align-items: center;
  box-shadow: var(--navbar-box-shadow);
  color: var(--main-theme-color);
}

.link {
  text-decoration: none;
  color: var(--main-theme-color);
}

.navbar_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
}

.switch_wrapper {
  display: flex;
  flex-wrap: wrap;
}

.switch_wrapper > *:not(:first-child) {
  margin-left: 12px;
}

.emoji_icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 3px;
  width: 18px;
  height: 18px;
}

.sun {
  background-image: url(assets/images/emogi/sun.png);
  right: 5px;
}

.moon {
  background-image: url(assets/images/emogi/moon.png);
  left: 5px;
}

.flag_ru {
  background-image: url(assets/images/emogi/flag-ru.png);
  width: 24px;
  height: 18px;;
  left: 5px;
}

.flag_us {
  background-image: url(assets/images/emogi/flag-us.png);
  width: 24px;
  height: 18px;
  right: 5px;
}
