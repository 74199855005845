footer {
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--navbar-background-color);
  box-shadow: var(--footer-box-shadow);
}

.footer_content {
  color: var(--main-text-color);
}