.organization_wrapper {
  margin: 20px 0;
  color: var(--main-text-color);

}

.title {
  font-size: 1.1em;
  font-weight: 500;
}

.timeActivity {
  font-size: 1.1em;
  font-style: italic;
}

.position {
  font-size: 1.1em;
  margin: 0 0 10px;
}