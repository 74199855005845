.contact, .contact_link {
  display: flex;
  align-items: center;
  color: var(--main-text-color);
  font-size: 18px;
}

.contact_link {
  text-decoration: none;
  color: var(--link-color);
}

.icon {
  margin-right: 10px;
}