main {
  padding: var(--navbar-padding-horizontal);
}

.photo_wrapper {
  width: 230px;
  height: 230px;
  margin: 30px;
}

.photo_wrapper > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profile_info {
  margin: 0 auto;
  font-size: 24px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 45px 0;
}

.profile_info > h1 {
  white-space: pre-line;
  letter-spacing: 5px;
  font-family: 'Quicksand';
}

.profile_info > h2 {
  letter-spacing: 2px;
  font-size: 1.25em;
  font-family: 'Source Code Pro';
}

.summary {
  font-size: 18px;
  margin: 20px 0;
  color: var(--main-text-color);
  white-space: pre-line;
}

.profile_details {
  padding-bottom: 20px;
}

.main_info, .common_info {
  flex: 1 1;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 60px;
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .common_info {
    display: inline-block;
  }
  .main_info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .profile_info {
    min-height: auto;
    margin: 0 0 45px 0;
  }
}
